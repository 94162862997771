import PageHeading from 'frontend/src/components/PageHeading';
import { useMutation, useQuery } from '@tanstack/react-query';
import { defineMessages, useIntl } from 'react-intl';
import { LESSONS_QUERY_KEY } from '../api/lessonsApi';
import SingleLessonView from './components/singleLessonView';
import React, { useState } from 'react';
import {
  deleteLesson,
  fetchLessons,
  saveLesson,
} from 'frontend/src/services/lessonService';
import { generalTranslations } from '@/generalTranslations';
import toast from 'react-hot-toast';
import {
  FlowConduit,
  SlideOverFlowConduit,
  useCancelFlow,
  useStartFlow,
} from '@/lib/flow';
import { queryClient } from '@/lib/queryClient';
import {
  creditLessonSchema,
  cycleLessonSchema,
  lessonSchema,
} from 'shared/src/schemas/lesson';
import ChooseLessonTypeDialog from './components/chooseLessonTypeDialog';
import { CycleLessonForm } from '@/form/Lesson/CycleForm';
import { DefaultLessonForm } from '@/form/Lesson/DefaultForm';
import { CreditLessonForm } from '@/form/Lesson/CreditForm';

const CREATE_CYCLE_LESSON = 'CREATE_CYCLE_LESSON';
const CREATE_CREDIT_LESSON = 'CREATE_CREDIT_LESSON';
const SELECT_LESSON_TYPE = 'SELECT_LESSON_TYPE';
const EDIT_LESSON = 'EDIT_LESSON';

export default function LessonsList() {
  const { formatMessage } = useIntl();
  const startFlow = useStartFlow();
  const closeFlow = useCancelFlow();

  const [page] = useState<number>(0);
  const [filters] = useState<Record<string, string>[]>([]);

  const { data: lessons, refetch: refetchLessons, error } = useQuery({
    queryKey: [...LESSONS_QUERY_KEY, page, ...filters],
    queryFn: () => {
      return fetchLessons({ all: true, showHidden: true });
    },
  });
  if (error) {
    console.error(error);
    toast.error(
      formatMessage(generalTranslations.failedToFetch, {
        resource: formatMessage(t.lessons).toLowerCase(),
      })
    );
  }

  const { mutate: onDeleteLesson, error: errorWhileDeletingLesson } =
    useMutation({
      mutationFn: deleteLesson,
      onSuccess: () => {
        toast.success(formatMessage(t.lessonDeleted));
        queryClient.invalidateQueries({ queryKey: LESSONS_QUERY_KEY });
        closeFlow();
      },
    });
  if (errorWhileDeletingLesson) {
    console.error(errorWhileDeletingLesson);
    toast.error(
      formatMessage(generalTranslations.failedToDelete, {
        resource: formatMessage(t.lesson).toLowerCase(),
      })
    );
  }

  const { mutate: createLesson, error: errorWhileCratingLesson } = useMutation({
    mutationFn: saveLesson,
    onSuccess: () => {
      toast.success(formatMessage(t.updatesSaved));
      queryClient.invalidateQueries({ queryKey: LESSONS_QUERY_KEY });
      closeFlow();
    },
  });
  if (errorWhileCratingLesson) {
    console.error(errorWhileCratingLesson);
    toast.error(
      formatMessage(generalTranslations.failedToSave, {
        resource: formatMessage(t.lesson).toLowerCase(),
      })
    );
  }

  return (
    <>
      <PageHeading
        title={formatMessage(t.lessonListPageTitle)}
        actions={[
          {
            label: formatMessage(t.createLesson),
            action: async () => {
              startFlow({ name: SELECT_LESSON_TYPE });
            },
            requiredPermission: 'lessons.create',
          },
        ]}
      />
      <ul role="list" className="flex flex-col gap-4">
        {lessons?.map((lesson) => (
          <li key={lesson.id}>
            <SingleLessonView
              lesson={lesson}
              onClick={async (newLesson) => {
                console.log(`Openingn lesson ${newLesson.id} [${newLesson.name}]`);
                await refetchLessons();
                startFlow({ name: EDIT_LESSON, payload: { lesson: newLesson } });
              }}
            />
          </li>
        ))}
      </ul>
      <FlowConduit
        flowMapping={{
          [SELECT_LESSON_TYPE]: () => {
            return (
              <ChooseLessonTypeDialog
                onCreditClick={() => {
                  startFlow({ name: CREATE_CREDIT_LESSON });
                }}
                onCycleClick={() => {
                  startFlow({ name: CREATE_CYCLE_LESSON });
                }}
              />
            );
          },
        }}
      />
      <SlideOverFlowConduit
        title={formatMessage(t.lesson)}
        flowMapping={{
          [CREATE_CREDIT_LESSON]: () => (
            <CreditLessonForm
              onSubmit={(data) => {
                console.log(data);
                createLesson(data);
              }}
            />
          ),
          [CREATE_CYCLE_LESSON]: () => (
            <CycleLessonForm
              onSubmit={(data) => {
                console.log(data);
                createLesson(data);
              }}
            />
          ),
          [EDIT_LESSON]: (params) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            console.log(`About to edit`, params.lesson.id, params.lesson.name);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const lesson = lessonSchema.parse(params.lesson);
            if (lesson.type === 'cycle') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const cycleLesson = cycleLessonSchema.parse(params.lesson);
              return (
                <CycleLessonForm
                  onSubmit={(data) => {
                    console.log(data);
                    createLesson(data);
                  }}
                  defaultValues={cycleLesson}
                  onDelete={onDeleteLesson}
                />
              );
            }
            if (lesson.type === 'credit') {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const creditLesson = creditLessonSchema.parse(params.lesson);
              return (
                <CreditLessonForm
                  onSubmit={(data) => {
                    console.log(data);
                    createLesson(data);
                  }}
                  defaultValues={creditLesson}
                  onDelete={onDeleteLesson}
                />
              );
            }
            return (
              <DefaultLessonForm
                onSubmit={(data) => {
                  console.log(data);
                  createLesson(data);
                }}
                defaultValues={lesson}
                onDelete={onDeleteLesson}
              />
            );
          },
        }}
      />
    </>
  );
}
const t = defineMessages({
  lessonListPageTitle: {
    defaultMessage: 'Lessons',
    id: `lessonListPage_title`,
  },
  createLesson: {
    defaultMessage: 'Create lesson',
    id: `lessonListPage_createLesson`,
  },
  lessons: {
    defaultMessage: 'lessons',
    id: `lessonListPage_lessons`,
  },
  lesson: {
    defaultMessage: 'lesson',
    id: `lessonListPage_lesson`,
  },
  updatesSaved: {
    defaultMessage: 'Updates saved',
    id: `lessonListPage_updatesSaved`,
  },
  lessonDeleted: {
    defaultMessage: 'Lesson deleted',
    id: `lessonListPage_lessonDeleted`,
  },
});
