import { collectionNames } from 'shared/src/collectionNames';
import { getFirestore } from '../lib/firebase';
import {
  appendBusiness,
  createBusinessQuery,
  createBusinessQueryComposite,
  currentBusinessId,
} from './businessService';
import {
  DocumentReference,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  where,
  writeBatch,
  limit,
  orderBy,
  or,
} from 'firebase/firestore';
import { Reservation, reservationSchema } from 'shared/src/schemas/reservation';
import { Person, personSchema, User } from 'shared/src/schemas/users';
import { locationSchema } from 'shared/src/schemas/location';
import { any, z } from 'zod';
import { fetchLessonSchema } from './lessonService';
import { fetchUserSchema } from './usersService';
import { fetchCurrentUser } from './currentUserService';
import { firebaseDate } from 'shared/src/schemas/date';
import { getCurrentUser } from './authService';

const fetchReservationSchema = reservationSchema.extend({
  user: any().transform(async (ref: DocumentReference) => {
    const snap = await getDoc(ref);
    const userDocumentSnapData = snap.data();
    if (!userDocumentSnapData) {
      console.error('User not found', ref.id);
    }
    return fetchUserSchema.parseAsync(userDocumentSnapData);
  }),
  lesson: any().transform(async (ref: DocumentReference) => {
    const snap = await getDoc(ref);
    const lessonDocumentSnapData = snap.data();
    if (!lessonDocumentSnapData) {
      console.error('Lesson not found', ref.id);
    }
    return fetchLessonSchema.parseAsync(lessonDocumentSnapData);
  }),
  location: any().transform(async (ref: DocumentReference) => {
    const snap = await getDoc(ref);
    const locationDocumentSnapData = snap.data();
    if (!locationDocumentSnapData) {
      console.error('Location not found', ref.id);
    }
    return locationSchema.parse(locationDocumentSnapData);
  }),
  person: any().transform(async (ref: DocumentReference) => {
    if (!ref) return;
    const snap = await getDoc(ref);
    const data = snap.data();
    if (!data) {
      console.error('Person not found', ref.id);
      return
    }
    return personSchema.parse({
      ...data,
      birthDate: firebaseDate.parse(data?.birthDate),
    });
  }),
  order: any().transform(async (ref: DocumentReference) => {
    if (!ref) return;
    ref.id;
  }),
  trainers: any().transform(
    async (ref: DocumentReference | DocumentReference[]) => {
      if (!ref) return [] as User[];
      if (!Array.isArray(ref)) {
        ref = [ref];
      }

      return Promise.all(
        ref.map(async (ref) => {
          const snap = await getDoc(ref);
          if (!snap.exists()) {
            console.error('Trainer not found', ref.id);
            return null;
          }
          return fetchUserSchema.parseAsync(snap.data());
        })
      );
    }
  ),
});

export type fetchReservation = z.infer<typeof fetchReservationSchema>;

export const fetchMyReservations = async ({
  start,
  end,
}: {
  start?: Date;
  end?: Date;
}) => {
  const currentUser = await fetchCurrentUser();
  return fetchReservations({
    userIds: [currentUser.id],
    start,
    end,
  });
};

export const fetchReservations = async ({
  userIds = [],
  cycleId,
  start,
  end,
  locationIds,
  trainerIds,
  lessonIds,
  showCancelled = false,
  n,
  order
}: {
  userIds?: string[];
  cycleId?: string;
  start?: Date;
  end?: Date;
  locationIds?: string[];
  trainerIds?: string[];
  showCancelled?: boolean;
  lessonIds?: string[];
  n?: number;
  order?: string;
}): Promise<fetchReservation[]> => {
  let locationsToFilterOn: string[] = locationIds || [];
  let trainerFilterOn: string[] = trainerIds ?? [];

  const currentUser = await fetchCurrentUser({});
  const businessId = currentBusinessId();

  const currentUserRoles: string[] = currentUser.roles[businessId];
  const isAdmin = currentUserRoles.includes('admin');
  const isManager = currentUserRoles.includes('manager');
  const isTrainer = currentUserRoles.includes('trainer');

  // Check if user is fetching for a single user or cycle, or is an admin, skip filtering based on manager/trainer role
  if (userIds.length === 1 || cycleId || isAdmin) {
    // Don't modify locations, keep existing filters (if any)
  } else {
    // Existing logic with const variables
    if (!userIds.length && isManager) {
      locationsToFilterOn =
        currentUser.managingLocations?.[businessId]?.map((loc) => loc.id) || [];
    }

    if (!userIds.length && isTrainer && !isManager && !isAdmin) {
      trainerFilterOn = [currentUser.id];
    }
  }

  const q = await createBusinessQueryComposite(
    collection(getFirestore(), collectionNames.reservations),
    n,
    order,
    ...(userIds.length
      ? [
        where(
          'user',
          'in',
          userIds.map((u) =>
            doc(getFirestore(), `${collectionNames.users}/${u}`)
          )
        ),
      ]
      : []),
    ...(start ? [where('start', '>=', start)] : []),
    ...(end ? [where('start', '<=', end)] : []),
    ...(locationsToFilterOn.length
      ? [
        where(
          'location',
          'in',
          locationsToFilterOn.map((id) =>
            doc(getFirestore(), collectionNames.addresses, id)
          )
        ),
      ]
      : []),
    ...(trainerFilterOn?.length
      ? [
        where(
          'trainers',
          'array-contains-any',
          trainerFilterOn.map((id) =>
            doc(getFirestore(), collectionNames.users, id)
          )
        ),
      ]
      : []),
    ...(lessonIds?.length
      ? [
        where(
          'lesson',
          'in',
          lessonIds.map((id) =>
            doc(getFirestore(), collectionNames.lesson, id)
          )
        ),
      ]
      : []),
    ...(showCancelled ? [] : [where('cancelled', '==', false)]),
    ...(cycleId ? [where('cycle', '==', cycleId)] : [])
  );
  const documentSnapShot = await getDocs(q);
  console.log(`Found ${documentSnapShot.docs.length} reservation(s)`);

  return fetchReservationSchema.array().parseAsync(
    documentSnapShot.docs.map((doc) => {
      console.log(`Parsing reservation ${doc.id}`, doc.data().lesson);
      return {
        ...doc.data(),
        start: doc.data().start.toDate(),
        end: doc.data().end.toDate(),
      };
    })
  );
};

export const saveReservation = async (res: Reservation) => {
  saveReservations([res]);
};

export const saveReservations = async (reservations: Reservation[]) => {
  const currentUser = getCurrentUser()
  console.log('save reservations', reservations);
  const batch = writeBatch(getFirestore());
  for (const res of reservations) {
    const reservation = await appendBusiness(res);

    if (!reservation.location) throw new Error("Location is required");
    if (!reservation.lesson) throw new Error("Lesson is required");
    if (!reservation.user) throw new Error("User is required");

    const reservationDoc = doc(
      getFirestore(),
      collectionNames.reservations,
      reservation.id
    );
    batch.set(reservationDoc, {
      ...reservation,
      trainers: reservation.trainers
        ? reservation.trainers.map((t) =>
          doc(getFirestore(), `${collectionNames.users}/${t.id}`)
        )
        : [],
      user: reservation.user
        ? doc(getFirestore(), `${collectionNames.users}/${reservation.user.id}`)
        : '',
      lesson: reservation.lesson
        ? doc(
          getFirestore(),
          `${collectionNames.lesson}/${reservation.lesson.id}`
        )
        : '',
      location: reservation.location
        ? doc(
          getFirestore(),
          `${collectionNames.addresses}/${reservation.location.id}`
        )
        : '',
      person: reservation.person
        ? doc(
          getFirestore(),
          `${collectionNames.users}/${reservation.user.id}/${collectionNames.family}/${reservation.person.id}`
        )
        : '',
      order: reservation.order
        ? doc(
          getFirestore(),
          `${collectionNames.orders}/${reservation.order.id}`
        )
        : '',
      updatedAt: new Date(),
      updatedBy: currentUser ? {
        id: currentUser.uid,
        name: currentUser.email,
      } : 'unknown',
      cancelled: reservation.cancelled || false,
    });
  }
  return batch.commit();
};

export async function countReservations({
  from,
  to,
}: {
  from: Date;
  to: Date;
}) {
  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.reservations),
    where('start', '>=', from),
    where('start', '<=', to)
  );
  const documentSnapShot = await getCountFromServer(q)
  return documentSnapShot.data().count;
}

export async function unlinkedReservations({
  from,
  to,
}: {
  from: Date;
  to: Date;
}) {
  const q = await createBusinessQuery(
    collection(getFirestore(), collectionNames.reservations),
    where('start', '>=', from),
    where('start', '<=', to),
    where('trainers', 'in', ['', []])
  )
  const documentSnapShot = await getCountFromServer(q)
  return documentSnapShot.data().count;
}