import {
  CycleReservation,
  cycleReservationSchema,
  miniReservationSchema,
} from 'shared/src/schemas/reservation';
import { defineMessages, useIntl } from 'react-intl';
import {
  UseFormReturn,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { v4 as uuid } from 'uuid';
import { Form } from 'frontend/src/layers/form/components/Form';
import { HiddenField } from 'frontend/src/layers/form/components/fields/HiddenField';
import { useQuery } from '@tanstack/react-query';
import {
  fetchPerson,
  fetchTrainers,
  fetchUsers,
} from 'frontend/src/services/usersService';
import { ComboBoxField } from 'frontend/src/layers/form/components/fields/ComboBoxField';
import { USERS_QUERY_KEY } from '@/pages/users/api/usersApi';
import { useIsAuthorized } from 'frontend/src/layers/authorization/hooks/useIsAuthorized';
import { array, string } from 'zod';
import { LESSONS_QUERY_KEY } from '@/pages/lessons/api/lessonsApi';
import { fetchLessons } from 'frontend/src/services/lessonService';
import { ShortTextField } from 'frontend/src/layers/form/components/fields/ShortTextField';
import { useEffect } from 'react';
import { DateTime } from 'luxon';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { generalTranslations } from '@/generalTranslations';
import { UserComboBox } from 'frontend/src/layers/form/components/fields/UserComboBox';
import { CheckIcon, XMarkIcon } from '@heroicons/react/20/solid';
// import { queryClient } from '@/lib/queryClient';
import PreviousReservations from '@/components/PreviousReservationsCycle';
import { LoadingSpinner } from 'frontend/src/components/LoadingSpinner';
import { Option } from 'frontend/src/layers/form/components/input/GenericComboBox';
import { getAvailabilitiesForLesson } from '@/services/timeSlotService';
import { CycleLesson } from 'shared/src/schemas/lesson';
import { cycleName } from 'shared/src/utils/cycleName'
import toast from 'react-hot-toast';
import { TRAINERS_QUERY_KEY } from '@/pages/availabilities/api/availabilitiesApi';
interface CycleReservationFormProps {
  defaultValues?: Partial<CycleReservation>;
  onSubmit: (data: CycleReservation) => void;
  onDelete?: (data: CycleReservation) => void;
}

// TODO: only show family when there is more than one person in the family
// TODO: auto select the first person in the family
export function CycleReservationForm({
  onSubmit,
  defaultValues,
  onDelete,
}: CycleReservationFormProps) {
  const { formatMessage } = useIntl();
  const isAllowedTo = useIsAuthorized();

  const { data: lessons } = useQuery({
    queryKey: [...LESSONS_QUERY_KEY],
    queryFn: () =>
      fetchLessons({ all: true, showHidden: true, showArchived: true, type: 'cycle' }),
    initialData:
      defaultValues && defaultValues.lesson ? [defaultValues.lesson] : [],
  });

  const { data: listOfTrainers } = useQuery({
    queryKey: [...TRAINERS_QUERY_KEY],
    queryFn: () => fetchTrainers(),
    initialData:
      defaultValues && defaultValues.trainers && defaultValues.trainers.length
        ? defaultValues.trainers
        : [],
  });
  const cycleReservationFormSchema = cycleReservationSchema.extend({
    lesson: string().transform((lessonId) =>
      lessons.find((lesson) => lesson.id === lessonId)
    ),
    trainers: string()
      .array()
      .transform((trainerIds) => {
        if (!trainerIds || !trainerIds.length) return '';
        return listOfTrainers.filter((trainer) =>
          trainerIds.includes(trainer.id)
        );
      })
      .optional(),
    order: string()
      .transform(() => defaultValues?.order)
      .optional(),
    reservations: array(
      miniReservationSchema.extend({
        user: string().transform((userId) =>
          userId ? fetchUsers({ ids: [userId] }).then((users) => users[0]) : {}
        ),
        personJoining: string().transform(async (personId) => {
          if (!personId) return '';
          return await fetchPerson(personId);
        }),
      })
    ).default([]),
  });
  const formReturn = useForm({
    resolver: zodResolver(cycleReservationFormSchema),
    defaultValues: {
      id: defaultValues?.id ?? uuid(),
      ...defaultValues,
      lesson: defaultValues?.lesson?.id,
      order: defaultValues?.order?.id,
      trainers: defaultValues?.trainers?.map((x) => x.id),
      reservations: defaultValues?.reservations?.map((reservation) => ({
        cancelled: reservation.cancelled || false,
        id: reservation.id,
        user: reservation.user.id,
        personJoining: reservation.personJoining
          ? reservation.personJoining?.id
          : reservation.user.id,
        personalFeedback: reservation.personalFeedback,
      })),
    },
  });
  const start = useWatch({
    control: formReturn.control,
    name: 'start',
  });
  const lesson = useWatch({
    control: formReturn.control,
    name: 'lesson',
  });
  const reservationsWatcher = useWatch({
    control: formReturn.control,
    name: 'reservations',
  });
  const end = useWatch({
    control: formReturn.control,
    name: 'end',
  });
  const trainersWatcher = useWatch({
    control: formReturn.control,
    name: 'trainers',
  });
  const cycleWatcher = useWatch({
    control: formReturn.control,
    name: 'cycle',
  });

  const currentLesson = lessons.find((l) => l.id === lesson) as CycleLesson || {};
  useEffect(() => {
    if (
      !currentLesson || !cycleWatcher || !currentLesson.cycles
    ) {
      return;
    }

    // Get the first date of the cycle
    const firstDate = currentLesson.cycles.find(c => c.id === cycleWatcher)?.cycleDates[0].date as Date;
    if (!firstDate) return;
    // End = start + duration (in hours)
    const end = DateTime.fromJSDate(firstDate)
      .plus({ hours: currentLesson.duration })
      .toJSDate();

    formReturn.setValue('start', firstDate);
    formReturn.setValue('end', end);
  }, [currentLesson, cycleWatcher]);

  const { fields: reservations, append: addReservation } = useFieldArray({
    control: formReturn.control,
    name: 'reservations',
  });

  const { data: newUsersForThisReservationWithFamily } = useQuery({
    queryKey: [
      ...USERS_QUERY_KEY,
      'forNewReservations',
      reservationsWatcher?.map((x) => x.user).filter(x => !!x).join(', '),
    ],
    queryFn: () => {
      const userIds = reservationsWatcher?.map((x) => x.user) || [];
      console.log('fetching users for new reservations', userIds);
      return fetchUsers({ ids: userIds, inclFamily: true });
    },
    enabled: !!reservationsWatcher?.length,
  });

  const {
    data: trainersAvailability,
    isFetching: isLoadingTrainersAvailability,
    isFetched: trainerAvailabilityFetched,
  } = useQuery({
    queryKey: ['trainerAvailability', start, lesson, cycleWatcher],
    enabled: !!start && !!lesson && !!listOfTrainers.length && !!cycleWatcher,
    initialData: [],
    queryFn: async () => {
      // Wait for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));
      if (!start || !end || !currentLesson || !cycleWatcher) return [];
      const location = currentLesson.cycles.find(c => c.id === cycleWatcher)?.location?.id;
      if (!location) return [];
      return getAvailabilitiesForLesson({
        end: new Date(end),
        lessonId: currentLesson.id,
        locationId: location,
        start: new Date(start),
      });
    },
  });

  const availableBadge = () => (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
      <CheckIcon className="h-4 w-4" />
      {formatMessage(t.available)}
    </span>
  );
  const unavailableBadge = () => (
    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
      <XMarkIcon className="h-4 w-4" />
      {formatMessage(t.unavailable)}
    </span>
  );
  const sortTrainers = (a: Option<string>, b: Option<string>) => {
    if (trainersAvailability) {
      const aAvailable = trainersAvailability.find(
        (x) => x.trainer === a.value
      )?.available;
      const bAvailable = trainersAvailability.find(
        (x) => x.trainer === b.value
      )?.available;
      const aSelected = trainersWatcher?.find((x) => x === a.value);
      const bSelected = trainersWatcher?.find((x) => x === b.value);
      if (aSelected && bSelected) return a.label.localeCompare(b.label);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      if (aAvailable && bAvailable) return a.label.localeCompare(b.label);
      if (aAvailable && !bAvailable) return -1;
      if (!aAvailable && bAvailable) return 1;
      if (!aAvailable && !bAvailable) return a.label.localeCompare(b.label);
    }
    return a.label.localeCompare(b.label);
  };
  return (
    <Form
      onErrors={(errors) => {
        console.log(errors)
        toast.error(formatMessage(generalTranslations.somethingWentWrong))
      }}
      OnSubmit={(data) => {
        console.log('data', data)
        if (!data.reservations.length || !data.reservations[0].user.id) {
          toast.error(formatMessage(t.noUsersSelected))
          return
        }
        data.reservations = data.reservations.filter(x => !!x.user.id)
        return onSubmit(data)
      }}
      formReturn={formReturn as unknown as UseFormReturn<CycleReservation>}
      readOnly={
        (defaultValues as CycleReservation)?.cancelled ||
        (!isAllowedTo('reservations.write') &&
          !isAllowedTo('reservations.giveFeedback'))
      }
    >
      <HiddenField name="id" />
      <ComboBoxField
        disabled={!isAllowedTo('reservations.write')}
        name="lesson"
        label={formatMessage(t.lesson)}
        options={lessons.map((lesson) => ({
          value: lesson.id,
          label: lesson.name,
          key: lesson.id,
        }))}
      />
      <ComboBoxField
        disabled={!isAllowedTo('reservations.write') || !currentLesson || !currentLesson.cycles}
        name="cycle"
        label={formatMessage(t.cycle)}
        options={(currentLesson.cycles || []).map((cycle) => ({
          value: cycle.id,
          label: cycleName(cycle, cycle.location) || '',
          key: cycle.id,
        }))}
        hint={!currentLesson || !currentLesson.cycles ? formatMessage(t.pleaseSelectLesson) : ''}
      />
      <ComboBoxField
        multiple
        disabled={!isAllowedTo('reservations.write')}
        name="trainers"
        options={listOfTrainers.map((trainer) => ({
          label: `${trainer.firstName} ${trainer.lastName}`,
          key: trainer.id,
          value: trainer.id,
        }))}
        customSorting={sortTrainers}
        optionRender={(option) => (
          <span className="flex gap-4">
            {option.label}
            {!trainerAvailabilityFetched ? (
              isLoadingTrainersAvailability && (
                <LoadingSpinner className="!h-4 !w-4" />
              )
            ) : trainersAvailability?.find((x) => x.trainer === option.value)
              ?.available ? (
              availableBadge()
            ) : (
              unavailableBadge()
            )}
          </span>
        )}
        label={formatMessage(t.trainer)}
      />

      <ShortTextField
        disabled={!isAllowedTo('reservations.giveFeedback')}
        name="feedback.comment"
        label={formatMessage(t.generalComment)}
        hint={formatMessage(t.commentsHint)}
      />
      <PreviousReservations cycleId={defaultValues?.cycle || 'theCycle'} />
      <div
        className='pl-4 border-l-2'
        key={
          newUsersForThisReservationWithFamily?.map((x) => x.id).join(', ') ||
          defaultValues?.cycle ||
          'theCycle'
        }
      >
        {reservations.map((reservation, index) => (
          <div
            className={`w-full gap-4 align-baseline py-4 `}
            key={reservation.id}
          >
            <div className="flex w-full gap-4">
              <div className="w-full">
                <UserComboBox
                  disabled={
                    !isAllowedTo('reservations.write') || reservation.cancelled
                  }
                  name={`reservations[${index}].user`}
                  label={formatMessage(t.reservationUser)}
                  users={newUsersForThisReservationWithFamily || []}
                />
              </div>
              <div className="w-full">
                <ComboBoxField
                  disabled={
                    !isAllowedTo('reservations.write') || reservation.cancelled
                  }
                  name={`reservations[${index}].personJoining`}
                  label={formatMessage(t.reservationName)}
                  options={
                    (newUsersForThisReservationWithFamily || [])
                      .find((x) => x.id == reservationsWatcher?.[index]?.user)
                      ?.family?.map((fam) => ({
                        value: fam.id,
                        label: `${fam.firstName} ${fam.lastName}`,
                        key: fam.id,
                      })) || []
                  }
                />
              </div>
            </div>
            <div className="flex w-full gap-4">
              <div className="w-full">
                <ShortTextField
                  disabled={!isAllowedTo('reservations.giveFeedback')}
                  name={`reservations[${index}].personalFeedback.comment`}
                  label={formatMessage(t.personalFeedback)}
                  hint={formatMessage(t.personalFeedbackHint)}
                />
              </div>
              <div className="w-auto flex items-center pb-2">
                {(defaultValues as CycleReservation)?.id &&
                  !reservation.cancelled &&
                  isAllowedTo('reservations.write') &&
                  onDelete && (
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md text-red-600 px-3 py-2 text-sm font-semibold hover:shadow-sm hover:bg-red-500 hover:text-white"
                      onClick={() => {
                        if (
                          !window.confirm(
                            formatMessage(t.cancelReservationForUSerRequest)
                          )
                        ) {
                          return;
                        }
                        onDelete({
                          ...(defaultValues as CycleReservation),
                          reservations: (
                            defaultValues as CycleReservation
                          ).reservations.filter(
                            (x) =>
                              x.id == defaultValues?.reservations?.[index].id
                          ),
                        });
                      }}
                    >
                      <XMarkIcon className="h-6 w-6" />
                    </button>
                  )}
              </div>
            </div>
          </div>
        ))}
        {(!defaultValues || !defaultValues.id) && (

          <button
            type="button"
            className='flex justify-center gap-4 items-center'
            onClick={() => addReservation({
              cancelled: false,
              id: uuid(),
              personalFeedback: { comment: '' },
              user: '',
              personJoining: '',
            })}>
            <PlusIcon className="h-4 w-4" />
            {formatMessage(t.addUser)}
          </button>
        )}
      </div>

      {
        (defaultValues as CycleReservation)?.id &&
        onDelete &&
        !(defaultValues as CycleReservation)?.cancelled && (
          <button
            type="button"
            className="inline-flex justify-center rounded-md text-red-600 px-3 py-2 text-sm font-semibold hover:shadow-sm hover:bg-red-500 hover:text-white"
            onClick={() => {
              if (!window.confirm(formatMessage(t.cancelReservationRequest))) {
                return;
              }
              onDelete(defaultValues as CycleReservation);
            }}
          >
            <TrashIcon className="h-5 w-5" />
            {formatMessage(generalTranslations.delete, {
              resource: formatMessage(t.thisReservation),
            })}
          </button>
        )
      }
    </Form >
  );
}

const t = defineMessages({
  reservationName: {
    defaultMessage: 'Who will be joining',
    id: 'cyclereservationform_reservationName',
  },
  reservationNameHint: {
    defaultMessage: 'Name of the reservation',
    id: 'cyclereservationform_reservationNameHint',
  },
  reservationUser: {
    defaultMessage: 'User',
    id: 'cyclereservationform_reservationUser',
  },
  person: {
    defaultMessage: 'Person joining',
    id: 'cyclereservationform_person',
  },
  lesson: {
    defaultMessage: 'Lesson',
    id: 'cyclereservationform_lesson',
  },
  trainer: {
    defaultMessage: 'Trainer',
    id: 'cyclereservationform_trainer',
  },
  generalComment: {
    defaultMessage: 'General feedback for this lesson',
    id: 'cyclereservationform_genralComment',
  },
  comments: {
    defaultMessage: 'Comments',
    id: 'cyclereservationform_comments',
  },
  commentsHint: {
    defaultMessage: 'Comments about this lesson.',
    id: 'cyclereservationform_commentsHint',
  },
  personalFeedback: {
    defaultMessage: 'Personal feedback',
    id: 'cyclereservationform_personalFeedback',
  },
  personalFeedbackHint: {
    defaultMessage: 'Feedback about this lesson for this person.',
    id: 'cyclereservationform_personalFeedbackHint',
  },
  thisReservation: {
    defaultMessage: 'this reservation',
    id: 'cyclereservationform_thisReservation',
  },
  cancelReservationRequest: {
    defaultMessage: 'Do you really want to cancel this reservation?',
    id: 'cyclereservationform_cancelReservationRequest',
  },
  cancelReservationForUSerRequest: {
    defaultMessage:
      'Do you really want to cancel this reservation for this user?',
    id: 'cyclereservationform_cancelReservationForUSerRequest',
  },
  available: {
    defaultMessage: 'Available',
    id: 'cyclereservationform_available',
  },
  unavailable: {
    defaultMessage: 'Unavailable',
    id: 'cyclereservationform_unavailable',
  },
  cycle: {
    defaultMessage: 'Cycle',
    id: 'cyclereservationform_cycle',
  },
  pleaseSelectLesson: {
    defaultMessage: 'Please select a lesson',
    id: 'cyclereservationform_pleaseSelectLesson',
  },
  addUser: {
    defaultMessage: 'Add user',
    id: 'cyclereservationform_addUser',
  },
  noUsersSelected: {
    defaultMessage: 'No users selected',
    id: 'cyclereservationform_noUsersSelected',
  },
});
