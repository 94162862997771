export const firebaseConfig = {
  admin: {
    apiKey: 'AIzaSyA7BXbLix99po82qguZcWCyVKNuvsrV8GE',
    authDomain: "sportsplannr-staging.firebaseapp.com",
    projectId: 'sportsplannr-staging',
    storageBucket: 'sportsplannr-staging.appspot.com',
    messagingSenderId: '566315326055',
    appId: '1:566315326055:web:ea1aa541f12ef8bc8552c2',
    measurementId: 'G-D0G6GHN9EZ',
  },
  user: {
    apiKey: 'AIzaSyA7BXbLix99po82qguZcWCyVKNuvsrV8GE',
    authDomain: "sportsplannr-staging.firebaseapp.com",
    projectId: 'sportsplannr-staging',
    storageBucket: 'sportsplannr-staging.appspot.com',
    messagingSenderId: '566315326055',
    appId: '1:566315326055:web:85cc6a9a349a20948552c2',
    measurementId: 'G-3MG4XE60KM',
  },
  superAdmin: {
    apiKey: 'AIzaSyA7BXbLix99po82qguZcWCyVKNuvsrV8GE',
    authDomain: "sportsplannr-staging.firebaseapp.com",
    projectId: 'sportsplannr-staging',
    storageBucket: 'sportsplannr-staging.appspot.com',
    messagingSenderId: '566315326055',
    appId: '1:566315326055:web:0ee3ba602a6f3dbe8552c2',
    measurementId: 'G-CVBVCV6CL5',
  },
  auth: {
    apiKey: 'AIzaSyA7BXbLix99po82qguZcWCyVKNuvsrV8GE',
    authDomain: "sportsplannr-staging.firebaseapp.com",
    projectId: 'sportsplannr-staging',
    storageBucket: 'sportsplannr-staging.appspot.com',
    messagingSenderId: '566315326055',
    appId: '1:566315326055:web:d9872060e708a0818552c2',
    measurementId: 'G-1F5DQKE3WQ',
  },
  site: {
    apiKey: "AIzaSyA7BXbLix99po82qguZcWCyVKNuvsrV8GE",
    authDomain: "sportsplannr-staging.firebaseapp.com",
    projectId: "sportsplannr-staging",
    storageBucket: "sportsplannr-staging.appspot.com",
    messagingSenderId: "566315326055",
    appId: "1:566315326055:web:9dcfb3b098c427b88552c2",
    measurementId: "G-310E38CB3E"
  }
};

export const algoliaConfig = {
  appId: 'Q68GL6XRAI',
  searchKey: '07ff81c6e043ea5b31c935242d9e35be',
  businessId: null
};

export const reCaptchaConfig = {
  siteKey: '6LcLsbwmAAAAAPt-P1ZohOYNjmjcUp1LGbV_sw_P',
};

export const sentryConfig = {
  adminDsn:
    'https://573ec0e7f8244c8f85a2723364325aa1@o4505589408071680.ingest.sentry.io/4505589412003840',
  userDsn:
    'https://45a0595fc19444e3beb9e9a80d011660@o4505589408071680.ingest.sentry.io/4505589556379648',
  functionsDsn:
    'https://9919133de58c95040b47f90f08816255@o4505589408071680.ingest.us.sentry.io/4506552573231104',
};

export const banner = 'STAGING';

export const authUrl = 'https://auth-staging.proswimlab.be';
export const superAdmin = 'https://superadmin.swimplannr.online/';
export const admin = 'https://admin-staging.proswimlab.be';
export const user = 'https://platform-staging.proswimlab.be';

export const businessId = "psl-staging"